import { Box } from '@mui/material';
import React from 'react';

import mgLogoMini from '../app/assets/mg_mini.png';
import mgLogoTransp from '../app/assets/mg_transp.png';

export const ManguearLogo: React.FC<{ isMobile: boolean }> = ({
  isMobile,
}): JSX.Element => {
  return (
    <Box
      alt="Manguear"
      component="img"
      src={isMobile ? mgLogoMini : mgLogoTransp}
      sx={{ width: 50 }}
    />
  );
};
