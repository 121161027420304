import { Select, SelectChangeEvent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { ValidationUserFilter } from '../../api/admin-account-validations/admin-validation.types';

const useValidateUserFilter = () => {
  const [filterState, setFilterState] = useState<ValidationUserFilter>('ALL');

  useEffect(() => {
    const params = new URLSearchParams();

    const value = params.get('user_filter') as ValidationUserFilter;

    if (value) {
      setFilterState(value);
    }
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    const params = new URLSearchParams();
    const value = event.target.value as ValidationUserFilter;
    params.set('user_filter', value);
    setFilterState(value);
  };

  const Filter = () => {
    return (
      <Select
        labelId="filter-select"
        id="filter-select"
        name="filter-select"
        value={filterState}
        label="Filtro"
        onChange={handleChange}
      >
        <MenuItem id="ALL" value={'ALL'}>
          Todos
        </MenuItem>
        <MenuItem id="IN_PROGRESS" value={'IN_PROGRESS'}>
          Esperando validacion
        </MenuItem>
        <MenuItem id="IN_PROGRESS_BACKOFFICE" value={'IN_PROGRESS_BACKOFFICE'}>
          Esperando validacion manual
        </MenuItem>
        <MenuItem id="VALIDATED" value={'VALIDATED'}>
          Validado
        </MenuItem>
        <MenuItem id="REJECTED" value={'REJECTED'}>
          Rechazado
        </MenuItem>
      </Select>
    );
  };

  return {
    Filter,
    filterState,
  };
};

export default useValidateUserFilter;
