import { Container, Stack, Typography } from '@mui/material';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { BasicError } from '../../api/common/common.error.types';
import SpaceSizes from '../../theme/foundations/spacing/SpaceSizes';
import { ErrorBasicType } from './Error.types';

const ErrorShower = ({ error }: { error: ErrorBasicType | undefined }) => {
  const { t } = useTranslation();
  return (
    <Container maxWidth={false} disableGutters>
      <Stack p={SpaceSizes.xs}>
        {error && 'data' in error ? (
          <Stack direction="row" spacing={1}>
            <Typography color="red" fontWeight="bold">
              {`Error ${(error as FetchBaseQueryError).status}:`}
            </Typography>
            <Typography color="red">
              {((error as FetchBaseQueryError).data as BasicError).error}
            </Typography>
          </Stack>
        ) : (
          <Stack alignItems="center" justifyContent="center">
            <Typography color="red">
              Ha ocurrido un error por favor intente nuevamente
            </Typography>
          </Stack>
        )}
      </Stack>
    </Container>
  );
};

export default ErrorShower;
