import React, { ComponentType, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../utils/auth.context';
import { AppLoader } from '../AppLoader';

interface ProtectedRouteProps {
  component: ComponentType;
  roles?: string[];
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: ParamComp,
  roles,
}) => {
  console.log(process.env);

  const { isLoggingIn } = useContext(AuthContext);

  const userDetails = localStorage.getItem('credentials');

  if (isLoggingIn) return <AppLoader />;

  if (userDetails) return <ParamComp />;

  return <Navigate to="/login" />;
};
