import { ThemeOptions } from '@mui/material/styles';

const green = '#59E603';
const darkGreen = '#51D302';
const pink = '#FE77E0';
const darkPink = 'DF6CC5';
const blue = '#05058D';
const darkBlue = '0606AB';
const gray = '#282628';
const lightGray = '#E8E8E8';

const primaryFontColor = '#1B1B1B'; // BLACK GREY
const secondaryFontColor = '#CDCDCD'; // LIGHT GREY

const warningColor = '#FFF971';
const errorColor = '#d50000';
const errorColorLight = '#ea868f';

export const backgroundColor = '#F7F7F7';
export const greyShadow = secondaryFontColor;

declare module '@mui/material/styles' {
  interface Palette {
    gradients: {
      blueGradient: string;
    };
  }
  interface PaletteOptions {
    gradients: {
      blueGradient: string;
    };
  }
}

// Setting default theme options that apply for any brand
export const defaultThemeOptions: ThemeOptions = {
  typography: {
    fontFamily: '"Montserrat"',
  },
  palette: {
    primary: {
      main: primaryFontColor,
    },
    secondary: {
      main: secondaryFontColor,
    },
    warning: {
      main: warningColor,
    },
    error: {
      main: errorColor,
      light: errorColorLight,
    },
    background: {
      default: blue,
      paper: primaryFontColor,
    },
    text: {
      primary: primaryFontColor,
      secondary: secondaryFontColor,
    },
    gradients: {
      blueGradient:
        'linear-gradient(90deg, rgba(0,45,203,0.5214460784313726) 0%, rgba(0,4,187,0.8127626050420168) 32%, rgba(0,22,170,0.6222864145658263) 100%)',
    },
  },
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true, // No more ripple, on the whole application!
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          background: lightGray,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          background: lightGray,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          background: lightGray,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: green,
          color: primaryFontColor,
          '&:hover': {
            backgroundColor: darkGreen,
          },
          ':disabled': {
            color: lightGray,
          },
        },
        text: {
          color: pink,
          '&:hover': {
            color: darkPink,
          },
          '&:disabled': {
            color: lightGray,
          },
        },
        outlined: {
          color: primaryFontColor,
          border: '1px solid #393939',
          '&:hover': {
            color: '#393939',
            border: '1px solid #393939',
          },
        },
      },
    },
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //       '& label.Mui-focused': {
    //         color: primaryFontColor,
    //       },
    //       '& .MuiInput-underline:after': {
    //         borderBottomColor: primaryFontColor,
    //       },
    //       '& .MuiOutlinedInput-root': {
    //         '& fieldset': {
    //           borderColor: primaryFontColor,
    //         },
    //         '&:hover fieldset': {
    //           borderColor: primaryFontColor,
    //         },
    //         '&.Mui-focused fieldset': {
    //           borderColor: primaryFontColor,
    //         },
    //       },
    //     },
    //   },
    // },
    MuiIconButton: {
      styleOverrides: {
        root: {
          background: green,
          color: primaryFontColor,
          '&:hover': {
            background: darkGreen,
          },
          margin: '4px',
        },
      },
    },
  },
};
