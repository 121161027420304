import Env from '../../env/env';
import { api } from '../Api';
import { LoggedUserSession, LoginUser } from './Admins.types';

export const adminsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoggedUserSession, LoginUser>({
      query: ({ login, password }) => ({
        url: `${Env.REACT_APP_BASE_API_URL_ADMINS}admin-users/login`,
        method: 'POST',
        body: {
          login,
          password,
        },
      }),
      invalidatesTags: [],
    }),
  }),
});

export const { useLoginMutation } = adminsApi;
