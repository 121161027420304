import React from 'react';
import { ValidateUserItemProps } from './validateList.types';
import { Button, Grid, Stack, Typography } from '@mui/material';
import SpaceSizes from '../../theme/foundations/spacing/SpaceSizes';
import { useNavigate } from 'react-router-dom';
import { greyShadow } from '../../theme/themes/default';

const validateUserItemStyle = {
  background: greyShadow,
  borderRadius: '50px',
};

const ValidateUserItem = ({
  nickname,
  requirements,
  user_id,
  validation_status,
}: ValidateUserItemProps) => {
  const navigate = useNavigate();

  return (
    <Stack padding={SpaceSizes.xs} style={validateUserItemStyle}>
      <Stack spacing={SpaceSizes.xxs}>
        <Typography variant="h5" fontWeight={550} align="center">
          {nickname}
        </Typography>
        <Grid spacing={2} container>
          <Grid item xs={6}>
            <Stack justifyContent="center">
              <Typography fontWeight={550}>{validation_status}</Typography>
              <Stack>
                {requirements.map((req) => (
                  <Typography>{req}</Typography>
                ))}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack
              justifyContent="center"
              alignItems="end"
              width="100%"
              height="100%"
            >
              <Button
                variant="contained"
                onClick={() => {
                  navigate(`/validate-user/${user_id}`);
                }}
              >
                Ir a Validar
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
};

export default ValidateUserItem;
