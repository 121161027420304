import './LoadingButton.css';

import { LoadingButton as LBMui } from '@mui/lab';
import React, { PropsWithChildren } from 'react';

interface LoadingButtonProps extends PropsWithChildren {
  disabled?: boolean;
  loading: boolean;
  onClick?: () => void;
  fitContent?: boolean;
  type?: 'button' | 'submit' | 'reset';
}

const LoadingButton = ({
  disabled = false,
  loading,
  onClick,
  children,
  fitContent,
  type = 'button',
}: LoadingButtonProps) => {
  return (
    <LBMui
      disabled={disabled}
      loading={loading}
      sx={fitContent ? { width: 'fit-content' } : {}}
      type={type}
      variant="contained"
      onClick={onClick}
    >
      {children}
    </LBMui>
  );
};

export default LoadingButton;
