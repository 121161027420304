import { Box } from '@mui/material';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { AppLoader } from '../components/AppLoader';
import { Layout } from '../layout/Layout';
import { backgroundColor } from '../theme/themes/default';

const isLoading = false;

function App() {
  if (isLoading) {
    return <AppLoader />;
  }

  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundColor: backgroundColor,
        backgroundSize: 'cover',
      }}
    >
      <Routes>
        <Route element={<Layout />} path="*" />
      </Routes>
    </Box>
  );
}

export default App;
