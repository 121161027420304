import React from 'react';
import BigCentredSpinner from '../../components/BigCentredSpinner';
import ErrorShower from '../../components/ErrorShower/ErrorShower';
import { Grid, Stack, Typography } from '@mui/material';
import ValidateUserItem from './ValidateUserItem';
import SpaceSizes from '../../theme/foundations/spacing/SpaceSizes';
import { useGetUsersForValidateQuery } from '../../api/admin-account-validations/AdminValidations.api';
import useValidateUserFilter from './useValidateUserFilter';

const ValidateUserListView = () => {
  const { Filter, filterState } = useValidateUserFilter();

  const { data, isLoading, error, isFetching } = useGetUsersForValidateQuery({
    filter: filterState,
  });

  if (error) return <ErrorShower error={error} />;

  if (isLoading) return <BigCentredSpinner />;

  return (
    <Stack>
      <Typography variant="h4" fontWeight={550}>
        Listado de usuarios por validar
      </Typography>
      {<Filter />}
      {isFetching ? (
        <Stack padding={SpaceSizes.md}>
          <BigCentredSpinner />
        </Stack>
      ) : (
        <Grid container spacing={2} padding={SpaceSizes.xs}>
          {data?.map((us) => (
            <Grid
              item
              key={us.user_id}
              xs={12}
              sm={12}
              md={6}
              lg={4}
              id={us.nickname}
            >
              <ValidateUserItem
                user_id={us.user_id}
                nickname={us.nickname}
                requirements={us.requirements || []}
                validation_status={us.validation_status}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Stack>
  );
};

export default ValidateUserListView;
