import { fetchBaseQuery, retry } from '@reduxjs/toolkit/query';

import Env from '../env/env';
import getJWTToken from '../utils/getJWTToken';
import { QUERIES_NO_TOKEN_NEEDED } from './common/noTokenQueries';

export const baseQuery = fetchBaseQuery({
  baseUrl: Env.REACT_APP_BASE_API_URL,
  prepareHeaders: async (headers, api) => {
    const token = getJWTToken();

    const tokenNeeded = !QUERIES_NO_TOKEN_NEEDED.includes(api.endpoint);

    if (token && tokenNeeded) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    headers.set('X-Auth-Token', 'Fe5yEc?pVcKAIZqqakWZTOrUdl/i5C=4Qs50nfncs3NQk5YSkCgy8JdYYBzDFD7kDwtNRTYyV2L5xTngVkxPkvMGKqOBKjGtqK=kbbaKSx7P7o6ojDILvSzSTfQkp52rGxkMd4RNLYJZs39SMtCOWj1suTS5iL!B9G7vE-kd1Bttah8sD9ZQBzs0ZujGEsWnY1!hMYAf7lovCB/Xf99RkuGgQC=mgy!pbj063fsx9RJm2Fs6N7oycqN5A?iydfd9');

    return headers;
  },
});

export const baseQueryWithRetry = retry(baseQuery, { maxRetries: 1 });
