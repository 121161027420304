import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Grid, Stack, Typography } from '@mui/material';
import BackButton from '../../components/BackButton';
import SpaceSizes from '../../theme/foundations/spacing/SpaceSizes';
import BigCentredSpinner from '../../components/BigCentredSpinner';
import ErrorShower from '../../components/ErrorShower/ErrorShower';
import ShowRequirements from './ShowRequirements';
import UserValidateModal from './UserValidateModal';
import { useGetUserDataForValidateQuery } from '../../api/admin-account-validations/AdminValidations.api';

const ValidateUserView = () => {
  const { userId } = useParams();
  const [open, setOpen] = useState<undefined | boolean>(undefined);

  const { data, isLoading, error } = useGetUserDataForValidateQuery(
    {
      userId: userId || '',
    },
    { skip: userId === undefined },
  );

  if (isLoading) return <BigCentredSpinner />;

  if (error) return <ErrorShower error={error} />;

  return (
    <Stack padding={SpaceSizes.xxs}>
      <Stack>
        <BackButton />
      </Stack>
      <Stack padding={SpaceSizes.xs} spacing={SpaceSizes.xs}>
        <Typography variant="h5" fontWeight={550}>
          {data?.first_name} {data?.last_name} ({data?.nickname})
        </Typography>
        <Grid padding={SpaceSizes.xs} container>
          <Grid item xs={12} md={4}>
            <Stack spacing={SpaceSizes.xxs}>
              {data?.requirements?.map((req, idx) => (
                <ShowRequirements req={req} key={req.type + idx} />
              ))}
            </Stack>
          </Grid>
          <Grid item xs={12} md={8}>
            <Stack spacing={SpaceSizes.xs}>
              <Typography fontWeight={550}>Detalle</Typography>
              <Stack spacing={SpaceSizes.xxs}>
                <Stack direction="row" spacing={SpaceSizes.min}>
                  <Typography>Nombre:</Typography>
                  <Typography>
                    {data?.first_name} {data?.last_name}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={SpaceSizes.min}>
                  <Typography>Nickname:</Typography>
                  <Typography>{data?.nickname}</Typography>
                </Stack>
                <Stack direction="row" spacing={SpaceSizes.min}>
                  <Typography>Estado:</Typography>
                  <Typography>{data?.validation_status}</Typography>
                </Stack>
                <Stack direction="row" spacing={SpaceSizes.min}>
                  <Typography>DNI:</Typography>
                  <Typography>{data?.dni}</Typography>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <Stack
          direction="row"
          justifyContent="space-around"
          alignItems="center"
        >
          <Button
            variant="outlined"
            disabled={!userId}
            onClick={() => {
              setOpen(false);
            }}
          >
            Rechazar
          </Button>
          <Button
            variant="contained"
            disabled={!userId}
            onClick={() => {
              setOpen(true);
            }}
          >
            Validar
          </Button>
        </Stack>
      </Stack>
      {open !== undefined && (
        <UserValidateModal
          open={open !== undefined}
          setOpen={setOpen}
          goodLook={open}
          nickname={data?.nickname || ''}
          userId={userId}
        />
      )}
    </Stack>
  );
};

export default ValidateUserView;
