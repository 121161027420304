import { Box, Button, Modal, Stack, Switch, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import SpaceSizes from '../../theme/foundations/spacing/SpaceSizes';
import { AlertContext } from '../../utils/alert.context';
import { greyShadow } from '../../theme/themes/default';
import { useChangeValidateUserStateMutation } from '../../api/admin-account-validations/AdminValidations.api';

const styledBoxForModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '80vw', sm: '600px' },
  bgcolor: greyShadow,
  borderRadius: '5px',
  boxShadow: 24,
  p: 1,
};

const UserValidateModal = ({
  open,
  setOpen,
  goodLook,
  userId,
  nickname,
}: {
  open: boolean;
  setOpen: (op: boolean | undefined) => void;
  goodLook: boolean;
  userId?: string;
  nickname: string;
}) => {
  const [validate, { isSuccess, isError, isLoading }] =
    useChangeValidateUserStateMutation();

  const [face, setFace] = useState(false);
  const [dni, setDni] = useState(false);

  const { onAlert } = useContext(AlertContext);

  useEffect(() => {
    if (!isLoading && (isSuccess || isError)) {
      onAlert({
        message: isSuccess
          ? 'Cambiaste el estado de validacion del usuario'
          : 'No se puedo cambiar el estado de validacion',
        type: isSuccess ? 'success' : 'error',
      });
      setOpen(undefined);
    }
  }, [isSuccess, isError, isLoading]);

  const acceptValidation = () => {
    if (userId) {
      validate({
        user_id: userId,
        face_requirement: true,
        identification_images_requirement: true,
      });
    }
  };

  const rejectValidation = () => {
    if (userId) {
      validate({
        user_id: userId,
        face_requirement: face,
        identification_images_requirement: dni,
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Box sx={styledBoxForModal}>
        <Stack spacing={SpaceSizes.xxs} padding={SpaceSizes.xxs}>
          <Typography variant="h6" fontWeight={550}>
            ¿Estás seguro que deseas {goodLook ? 'validar' : 'rechazar'} este
            usuario?
          </Typography>
          <Typography>
            Estas apunto de {goodLook ? 'validar' : 'rechazar'} el usuario "
            {nickname}"
          </Typography>
          {!goodLook && (
            <Stack direction="row" justifyContent="center" alignItems="center">
              <Typography>Validación de cara</Typography>
              <Switch
                checked={face}
                onChange={() => {
                  setFace(!face);
                }}
              />
              <Typography fontWeight={300}>
                {face ? '(Validado)' : '(Rechazado)'}
              </Typography>
            </Stack>
          )}
          {!goodLook && (
            <Stack direction="row" justifyContent="center" alignItems="center">
              <Typography>Validación de documento</Typography>
              <Switch
                checked={dni}
                onChange={() => {
                  setDni(!dni);
                }}
              />
              <Typography fontWeight={300}>
                {dni ? '(Validado)' : '(Rechazado)'}
              </Typography>
            </Stack>
          )}
          <Stack spacing={SpaceSizes.xxs} padding={SpaceSizes.xs}>
            <Button
              onClick={() => {
                setOpen(undefined);
              }}
              variant="outlined"
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={goodLook ? acceptValidation : rejectValidation}
            >
              {goodLook ? 'Validar' : 'Rechazar'}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

export default UserValidateModal;
