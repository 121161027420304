export default {
  NODE_ENV: 'development',
  REACT_APP_BASE_API_URL:
    process.env.REACT_APP_BASE_API_URL || 'https://api.dev.manguear.com/',
  REACT_APP_BASE_API_URL_ADMINS:
    process.env.REACT_APP_BASE_API_URL_ADMINS ||
    process.env.REACT_APP_BASE_API_URL ||
    'https://api.dev.manguear.com/',
  REACT_APP_BASE_API_URL_ADMIN_ACCOUNT_VALIDATION:
    process.env.REACT_APP_BASE_API_URL_ADMIN_ACCOUNT_VALIDATION ||
    process.env.REACT_APP_BASE_API_URL ||
    'https://api.dev.manguear.com/',
  REACT_APP_GOLBALL_ENV: 'development',
  REACT_APP_LAMBDA_TOKEN: 'Fe5yEc?pVcKAIZqqakWZTOrUdl/i5C=4Qs50nfncs3NQk5YSkCgy8JdYYBzDFD7kDwtNRTYyV2L5xTngVkxPkvMGKqOBKjGtqK=kbbaKSx7P7o6ojDILvSzSTfQkp52rGxkMd4RNLYJZs39SMtCOWj1suTS5iL!B9G7vE-kd1Bttah8sD9ZQBzs0ZujGEsWnY1!hMYAf7lovCB/Xf99RkuGgQC=mgy!pbj063fsx9RJm2Fs6N7oycqN5A?iydfd9',
};
