import { CSSProperties } from 'react';
import { greyShadow } from '../../theme/themes/default';
import SpaceSizes from '../../theme/foundations/spacing/SpaceSizes';

export interface ModalCentredWrapperProps extends React.PropsWithChildren {
  maxWidth?: string;
  maxHeight?: string;
}

export const styledModalCentred: CSSProperties = {
  borderRadius: '5%',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
};
