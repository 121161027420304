import { Box, Container, useTheme } from '@mui/material';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import HomeView from '../views/HomeView';
import LoginView from '../views/LoginView';
import ProfileView from '../views/ProfileView';
import Header from './Header';
import { Listener } from './Listener';
import ValidateUserListView from '../views/validate-user-list/ValidateUserListView';
import ValidateUserView from '../views/validate-user/ValidateUserView';
import { ProtectedRoute } from '../components/auth/ProtectedRoutes';

export const Layout = (): JSX.Element => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <Header />
      <Listener />
      <Container component="main" maxWidth={false} sx={{ m: { xs: 0, sm: 2 } }}>
        <Box sx={{ ...theme.mixins.toolbar }} />
        <Routes>
          <Route element={<LoginView />} path="/login" />
          <Route
            element={<ProtectedRoute component={ProfileView} roles={[]} />}
            path="/profile"
          />
          <Route
            element={
              <ProtectedRoute component={ValidateUserListView} roles={[]} />
            }
            path="/validate-users"
          />
          <Route
            element={<ProtectedRoute component={ValidateUserView} roles={[]} />}
            path="/validate-user/:userId"
          />
          <Route
            element={<ProtectedRoute component={HomeView} roles={[]} />}
            path="/home"
          />
          <Route element={<LoginView />} path="*" />
        </Routes>
      </Container>
    </Box>
  );
};
