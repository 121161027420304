import { Box, Modal } from '@mui/material';
import React, { useState } from 'react';
import { greyShadow } from '../theme/themes/default';

interface ImageProps {
  alt: string;
  src: string;
  fallback: string;
  borderRadius?: string;
  width: string;
  height: string;
  zoom?: boolean;
}

export const styledBoxForImageModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '80vw', sm: '600px' },
  bgcolor: greyShadow,
  borderRadius: '5px',
  boxShadow: 24,
  p: 1,
};

export const ImageFallback = ({
  alt,
  src,
  height,
  width,
  fallback,
  borderRadius = '0',
  zoom = true,
  ...props
}: ImageProps) => {
  const [imgSrc, setImgSrc] = useState<string | undefined>(src);

  const [open, setOpen] = useState(false);

  if (zoom) {
    return (
      <>
        <img
          onClick={
            zoom
              ? () => {
                  setOpen(true);
                }
              : () => {}
          }
          alt={alt}
          height={height}
          src={imgSrc || fallback}
          style={{ borderRadius, cursor: 'pointer' }}
          width={width}
          onError={() => setImgSrc(fallback)}
          {...props}
        />
        {open && (
          <Modal
            open={open}
            onClose={() => {
              setOpen(false);
            }}
          >
            <Box sx={styledBoxForImageModal}>
              <img alt={alt} src={imgSrc} width="100%" />
            </Box>
          </Modal>
        )}
      </>
    );
  }

  return (
    <img
      alt={alt}
      height={height}
      src={imgSrc || fallback}
      style={{ borderRadius }}
      width={width}
      onError={() => setImgSrc(fallback)}
      {...props}
    />
  );
};
