import { Alert, AlertColor, Snackbar } from '@mui/material';
import React, { useContext } from 'react';

import { AlertContext } from '../utils/alert.context';

export const Listener: React.FC = () => {
  const { alert, onResetAlert } = useContext(AlertContext);

  return (
    <Snackbar autoHideDuration={6000} open={!!alert} onClose={onResetAlert}>
      <Alert severity={(alert?.type as AlertColor) ?? 'success'}>
        {alert?.message}
      </Alert>
    </Snackbar>
  );
};
