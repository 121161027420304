import type { Middleware } from '@reduxjs/toolkit';
import {
  combineReducers,
  configureStore,
  PreloadedState,
} from '@reduxjs/toolkit';

import { api } from './api/Api';
import Env from './env/env';

// Create the root reducer independently to obtain the RootState type
const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
});

/**
 * Add a Sentry error-capture middleware
 */
export const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  return next(action);
};

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    devTools: Env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) => {
      const middlewares = [api.middleware];

      middlewares.push(rtkQueryErrorLogger);

      return getDefaultMiddleware().concat(middlewares);
    },
  });
};

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
