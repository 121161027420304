import { CircularProgress, Stack } from '@mui/material';
import React from 'react';

const BigCentredSpinner = ({ big }: { big?: boolean }) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={big ? { height: '70vh' } : {}}
    >
      <CircularProgress size={big ? 50 : 40} />
    </Stack>
  );
};

export default BigCentredSpinner;
