import React from 'react';
import { ImageFallback } from '../../components/ImageFallback';
import { Stack, Typography } from '@mui/material';
import SpaceSizes from '../../theme/foundations/spacing/SpaceSizes';
import { UserValidationRequired } from '../../api/admin-account-validations/admin-validation.types';

const ShowRequirements = ({ req }: { req: UserValidationRequired }) => {
  const getImages = () => {
    if (req.type === 'identification_images')
      return (
        <Stack spacing={SpaceSizes.xxs}>
          {req.front_image && (
            <ImageFallback
              alt={'Frente DNI'}
              src={req.front_image}
              fallback={''}
              width={'200px'}
              height={'150px'}
            />
          )}
          {req.back_image && (
            <ImageFallback
              alt={'Dorso DNI'}
              src={req.back_image}
              fallback={''}
              width={'200px'}
              height={'150px'}
            />
          )}
        </Stack>
      );

    if (req.type === 'face')
      return (
        <Stack spacing={SpaceSizes.xxs}>
          {req.image && (
            <ImageFallback
              alt={'Identificacion Facial'}
              src={req.image}
              fallback={''}
              width={'200px'}
              height={'200px'}
            />
          )}
        </Stack>
      );

    return <Typography>No hay imagenes disponibles</Typography>;
  };

  return <Stack>{getImages()}</Stack>;
};

export default ShowRequirements;
