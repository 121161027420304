import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';

import mgLogo from '../app/assets/mg_ban.png';
import SpaceSizes from '../theme/foundations/spacing/SpaceSizes';
import { useNavigate } from 'react-router-dom';

const HomeView = () => {
  const navigate = useNavigate();
  return (
    <Stack>
      <Stack padding={SpaceSizes.xxs}>
        <Typography fontWeight={550} variant="h5">
          Bienvenido a Manguear Backoffice
        </Typography>
        <Stack padding={SpaceSizes.xxs}>
          <Typography>
            En este momento estamos construyendo la pagina para que puedas
            trabajar crack
          </Typography>
          <Button
            onClick={() => {
              navigate('/validate-users');
            }}
            variant="contained"
          >
            Ir a validar usuarios
          </Button>
        </Stack>
      </Stack>
      <Stack
        alignItems="center"
        bottom={15}
        justifyContent="center"
        position="absolute"
        width="100%"
      >
        <Box alt="Manguear" component="img" src={mgLogo} sx={{ width: 100 }} />
      </Stack>
    </Stack>
  );
};

export default HomeView;
