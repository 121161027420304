import React, { useEffect, useMemo, useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { isNonEmptyString, isNotNilOrEmpty } from 'ramda-adjunct';
import { useFormContext } from 'react-hook-form';

import SpaceSizes from '../../theme/foundations/spacing/SpaceSizes';
import { DefaultHelperText } from '../DefaultHelperText/DefaultHelperText.component';
import { InfoTooltip } from '../InfoTooltip/InfoTooltip';
import {
  DIGIT_REGEX,
  LOWER_REGEX,
  UPPER_REGEX,
} from './InputPassword.constants';
import { InputPasswordProps } from './InputPassword.types';

// This input was thinked to be used with React Hook forms
// Must be used with Form Provider or our Form Component
export const InputPassword = ({
  label = '',
  placeholder = '',
  id,
  required = true,
  labelAlign = 'left',
  disabled = false,
  validate = true,
  match = '',
  tooltip,
  onError,
}: InputPasswordProps) => {
  const {
    register,
    formState: { errors },
    trigger,
    watch,
  } = useFormContext();

  const theme = useTheme();

  const hasError = errors[id];

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  // Trick for only runs useEffect on validation case
  const inputValue = validate ? watch(id) : '';
  const matchToValidate = useMemo(() => {
    return validate && isNonEmptyString(inputValue) && isNonEmptyString(match);
  }, [validate, inputValue, match]);

  useEffect(() => {
    if (matchToValidate) {
      trigger(id);
    }
  }, [trigger, id, matchToValidate, match, inputValue]);

  return (
    <Stack spacing={SpaceSizes.min} width="100%">
      <Typography color="text.primary" textAlign={labelAlign}>
        {label}
      </Typography>
      <TextField
        {...register(id, {
          required: {
            value: required,
            message: 'Campo obligatorio',
          },
          ...(validate && {
            minLength: {
              value: matchToValidate ? 1 : 8,
              message: 'Debe tener al menos 6 caracteres',
            },
            maxLength: {
              value: 16,
              message: 'Puede tener hasta 16 caracteres',
            },
            validate: matchToValidate
              ? {
                  match: (value) =>
                    (matchToValidate ? match === value : true) || 'Coincidir',
                }
              : {
                  digit: (value) => DIGIT_REGEX.test(value) || 'Numero',
                  upper: (value) => UPPER_REGEX.test(value) || 'Mayuscula',
                  lower: (value) => LOWER_REGEX.test(value) || 'Minuscula',
                  match: (value) =>
                    (matchToValidate ? match === value : true) || 'Coincidir',
                },
          }),
        })}
        color="primary"
        disabled={disabled}
        error={isNotNilOrEmpty(hasError)}
        InputProps={{
          style: { borderRadius: '8px' },
          inputProps: {
            'data-testid': id,
          },
          endAdornment: (
            <InputAdornment position="end">
              {tooltip && <InfoTooltip content={tooltip} />}
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        placeholder={placeholder}
        sx={{
          '& .MuiInputBase-input::placeholder': {
            color: theme.palette.text.primary,
          },
        }}
        type={showPassword ? 'text' : 'password'}
        variant="outlined"
        onError={onError}
        {...(isNonEmptyString(inputValue) && { onBlur: () => trigger(id) })}
      />
      {hasError && (
        <DefaultHelperText
          testId={`${id}InputHelperError`}
          value={hasError.message as string}
        />
      )}
    </Stack>
  );
};
