import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithRetry } from './baseQuery';

export const api = createApi({
  reducerPath: 'api',
  tagTypes: ['validations'],
  baseQuery: baseQueryWithRetry,
  endpoints: () => ({}),
});
