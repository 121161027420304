import { Typography, useTheme } from '@mui/material';
import React from 'react';

import SpaceSizes from '../../theme/foundations/spacing/SpaceSizes';
import type { DefaultHelperTextProps } from './DefaultHelperText.types';

export const DefaultHelperText = ({
  value,
  testId,
}: DefaultHelperTextProps) => {
  const theme = useTheme();

  return (
    <Typography
      data-testid={testId}
      fontSize={12}
      sx={{ color: theme.palette.error.main, paddingInline: SpaceSizes.sm }}
    >
      {value}
    </Typography>
  );
};
