import React from 'react';
import {
  ModalCentredWrapperProps,
  styledModalCentred,
} from './MordalCentredWrapper.types';
import { Stack, Box } from '@mui/material';
import SpaceSizes from '../../theme/foundations/spacing/SpaceSizes';

const ModalCentredWrapper = ({
  maxHeight,
  maxWidth,
  children,
}: ModalCentredWrapperProps) => {
  return (
    <Stack width="100vw" height="100vh">
      <Box
        width={{ xs: '90%', md: '40%' }}
        maxHeight={maxHeight}
        maxWidth={maxWidth}
        style={styledModalCentred}
        paddingX={{ xs: SpaceSizes.xxs, md: SpaceSizes.md }}
        paddingY={{ xs: SpaceSizes.xxs, md: SpaceSizes.sm }}
      >
        {children}
      </Box>
    </Stack>
  );
};

export default ModalCentredWrapper;
