import { createTheme, ThemeProvider } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import React, { PropsWithChildren } from 'react';

import { defaultThemeOptions } from '../theme/themes/default';

export const BrandThemeProvider = ({ children }: PropsWithChildren) => {
  const theme = createTheme(deepmerge(defaultThemeOptions, {} || {}));

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
