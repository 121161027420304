import { Stack, TextField, Typography, useTheme } from '@mui/material';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import SpaceSizes from '../../theme/foundations/spacing/SpaceSizes';
import { DefaultHelperText } from '../DefaultHelperText/DefaultHelperText.component';
import { InputProps } from './Input.types';

// This input was thinked to be used with React Hook forms
// Must be used with Form Provider or our Form Component
const Input = ({
  label = '',
  placeholder = '',
  errorMessage = '',
  id,
  type = 'text',
  required = true,
  labelAlign = 'left',
  disabled = false,
  validate,
  minRows = 1,
  onError,
}: InputProps) => {
  const theme = useTheme();
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const hasError = errors[id];

  return (
    <Stack spacing={SpaceSizes.min} width="100%">
      <Typography color="text.primary" textAlign={labelAlign}>
        {label}
      </Typography>
      <TextField
        {...register(id, {
          required,
          validate,
        })}
        color="primary"
        disabled={disabled}
        error={isNotNilOrEmpty(hasError)}
        InputProps={{
          inputProps: {
            'data-testid': id,
            autoComplete: 'current-password',
          },
          style: { borderRadius: '8px' },
        }}
        minRows={minRows}
        multiline={minRows > 1}
        placeholder={placeholder}
        sx={{
          '& .MuiInputBase-input::placeholder': {
            color: theme.palette.text.primary,
          },
        }}
        type={type}
        variant="outlined"
        onError={onError}
      />
      {hasError && (
        <DefaultHelperText
          testId={`${id}InputHelperError`}
          value={errorMessage}
        />
      )}
    </Stack>
  );
};

export default Input;
