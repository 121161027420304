import { AppBar, Box, Button, useTheme } from '@mui/material';
import React, { useContext } from 'react';

import { ManguearLogo } from '../components/ManguearLogo';
import useIsMobile from '../hooks/useIsMobile';
import SpaceSizes from '../theme/foundations/spacing/SpaceSizes';
import { AuthContext } from '../utils/auth.context';

const Header = () => {
  const theme = useTheme();

  const isMobile = useIsMobile();

  const { onLogout, isLoggedIn } = useContext(AuthContext);

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <Box
        py={SpaceSizes.xxs}
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <ManguearLogo isMobile={isMobile} />
        {isLoggedIn && (
          <Button
            sx={{
              position: 'fixed',
              top: 18,
              right: 25,
            }}
            variant="contained"
            onClick={() => {
              onLogout();
            }}
          >
            Cerrar Sesion
          </Button>
        )}
      </Box>
    </AppBar>
  );
};

export default Header;
