import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface BackButtonProps {
  handleBack?: () => void;
}

// TODO: LIBRARY COMPONENT
const BackButton = ({ handleBack }: BackButtonProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = () => {
    if (handleBack) {
      handleBack();
    } else {
      navigate(-1);
    }
  };

  return (
    <Button
      fullWidth={false}
      style={{ width: 'fit-content' }}
      onClick={handleClick}
    >
      <ArrowBackIcon />
      Volver
    </Button>
  );
};

export default BackButton;
