import type { ReactNode } from 'react';
import React, { createContext, useEffect, useMemo, useState } from 'react';

import { LoggedUserSession, UserDetails } from '../api/auth-admin/Admins.types';

export interface IAuthContext {
  userDetails?: UserDetails;
  jwt?: string;
  isLoggedIn: boolean;
  isLoggingIn: boolean;
  isActive: boolean;
  setIsLoggingIn: (val: boolean) => void;
  onLogin: (loginUser: LoggedUserSession) => void;
  onLogout: () => void;
}

export const AuthContext = createContext<IAuthContext>({
  userDetails: undefined,
  jwt: undefined,
  isLoggedIn: false,
  isLoggingIn: false,
  isActive: false,
  setIsLoggingIn: () => null,
  onLogin: () => null,
  onLogout: () => null,
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [userDetails, setUserDetails] = useState<UserDetails | undefined>();
  const [jwt, setJwt] = useState<string | undefined>();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const readCredentials = async () => {
    try {
      const userDetails = await localStorage.getItem('credentials');
      const jwt = await localStorage.getItem('token');
      if (!userDetails || !jwt) {
        logoutHandler();
      } else {
        setIsLoggedIn(true);
        setUserDetails(JSON.parse(userDetails));
        setJwt(jwt);
      }
    } catch (error) {
      logoutHandler();
    }
  };

  useEffect(() => {
    if (!userDetails) {
      readCredentials();
    }
  }, []);

  const storeCredentials = (credentials: UserDetails, jwtParam: string) => {
    try {
      localStorage.setItem('credentials', JSON.stringify(credentials));
      if (jwtParam) localStorage.setItem('token', jwtParam);
    } catch (error) {
      setIsLoggingIn(false);
    }
  };

  const resetCredentials = () => {
    localStorage.removeItem('credentials');
    localStorage.removeItem('token');
  };

  const loginHandler = (loggedUser: LoggedUserSession) => {
    setUserDetails(loggedUser.user);
    setJwt(loggedUser.jwtToken);
    setIsLoggedIn(true);
    storeCredentials(loggedUser.user, loggedUser.jwtToken);
  };

  const logoutHandler = () => {
    setUserDetails(undefined);
    setJwt(undefined);
    setIsLoggedIn(false);
    resetCredentials();
  };

  const returnObject = useMemo(() => {
    return {
      userDetails,
      jwt,
      isLoggedIn,
      isLoggingIn,
      setIsLoggingIn,
      isActive: isLoggedIn,
      onLogin: loginHandler,
      onLogout: logoutHandler,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  return (
    <AuthContext.Provider value={returnObject}>{children}</AuthContext.Provider>
  );
};
