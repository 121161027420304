import Env from '../../env/env';
import { api } from '../Api';
import {
  ChangeUserValidationStatus,
  GetUserDataForValidateResponse,
  GetUsersForValidateResponse,
  ValidationUserFilter,
} from './admin-validation.types';

export const adminValidationsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    changeValidateUserState: builder.mutation<void, ChangeUserValidationStatus>(
      {
        query: ({
          user_id,
          face_requirement,
          identification_images_requirement,
        }) => ({
          url: `${Env.REACT_APP_BASE_API_URL_ADMIN_ACCOUNT_VALIDATION}admin-account-validations/`,
          method: 'POST',
          body: {
            user_id,
            face_requirement,
            identification_images_requirement,
          },
        }),
        invalidatesTags: ['validations'],
      },
    ),
    getUsersForValidate: builder.query<
      GetUsersForValidateResponse[],
      { filter: ValidationUserFilter }
    >({
      query: ({ filter }) => ({
        url: `${Env.REACT_APP_BASE_API_URL_ADMIN_ACCOUNT_VALIDATION}admin-account-validations/users-for-validate`,
        method: 'GET',
        params: {
          filter: filter,
        },
      }),
      providesTags: ['validations'],
    }),
    getUserDataForValidate: builder.query<
      GetUserDataForValidateResponse,
      { userId: string }
    >({
      query: ({ userId }) => ({
        url: `${Env.REACT_APP_BASE_API_URL_ADMIN_ACCOUNT_VALIDATION}admin-account-validations/user-for-validate/${userId}`,
        method: 'GET',
      }),
      providesTags: ['validations'],
    }),
  }),
});

export const {
  useGetUsersForValidateQuery,
  useGetUserDataForValidateQuery,
  useChangeValidateUserStateMutation,
} = adminValidationsApi;
